<template>
    <ValidationObserver ref="wizardPage7" id="wizardPage7" name="wizardPage7">
        <div v-if="appData">

            <policy-alert :app-data="appData" :is-read-only="isReadOnly" @alertSaved="$emit('alertSaved', $event)"
                @handleError="$emit('handleError', $event)" :editable="false" />

            <policy-summary :app-data="appData" />

            <instructions :instructions-text="instructions" />

            <b-card border-variant="primary" body-class="px-4 py-4" class="mt-10">

                <declaration-form v-if="appData.acceptedQuote"
                    :formData="appData.acceptedQuote.declarationData.sumInsuredData"
                    :configuration="sumsDataTableConfiguration" :is-read-only="true" />
                <declaration-form v-if="appData.acceptedQuote"
                    :formData="appData.acceptedQuote.declarationData.excessData"
                    :configuration="excessDataTableConfiguration" :is-read-only="true" />

                <!-- Policy Identifier Section -->
                <div v-if="appData && appData.acceptedQuote">
                    <div class="row mb-8 mt-8">
                        <div class="col-md-4">
                            <div class="card h-100">
                                <div class="card-body">
                                    <h4 class="card-title text-center mb-8">Insurer & References</h4>

                                    <div class="form-group">
                                        <label>Accepted Insurer</label>
                                        <input type="text" class="form-control"
                                            :value="appData.acceptedQuote?.K_Insurer?.name" disabled />
                                    </div>

                                    <div class="form-group">
                                        <label>Quote Reference</label>
                                        <input type="text" class="form-control"
                                            v-model="appData.acceptedQuote.K_QuoteReference" disabled />
                                    </div>

                                    <div class="form-group mb-0">
                                        <label>Policy Number</label>
                                        <input type="text" class="form-control"
                                            v-model="appData.acceptedQuote.K_PolicyNumber" disabled />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Policy Terms Section -->
                        <div class="col-md-8">
                            <div class="card h-100">
                                <div class="card-body">
                                    <h4 class="card-title text-center mb-8">Endorsements & Conditions</h4>

                                    <div class="form-group">
                                        <label>Endorsements / Conditions</label>
                                        <textarea v-model="appData.acceptedQuote.K_EndorsementsConditions"
                                            class="form-control" rows="3" disabled></textarea>
                                    </div>

                                    <div class="form-group mb-0">
                                        <label>Subjectivities</label>
                                        <textarea v-model="appData.acceptedQuote.K_Subjectivities" class="form-control"
                                            rows="3" disabled></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Special Instructions Section -->
                <div class="card mb-8">
                    <div class="card-body">
                        <h4 class="card-title text-center mb-4">Special Invoicing Instructions</h4>

                        <div class="form-group mb-0">
                            <textarea v-model="appData.transaction.K_SpecialInvoicingInstructions" class="form-control"
                                rows="4" disabled></textarea>
                        </div>
                    </div>
                </div>

                <b-card border-variant="" body-class="px-2 py-2" class="mt-5 mb-5">
                    <h4 class="fs-2x fw-bolder mb-0 text-center py-4">
                        Financial Details
                    </h4>

                    <div v-if="appData.acceptedQuote">
                        <div class="form-group row">
                            <label class="col-2 col-form-label text-right">Base Premium</label>
                            <div class="col-2">
                                <currency-input class="form-control" v-model="appData.acceptedQuote.K_BasePremium"
                                    disabled />
                            </div>
                            <label class="col-2 col-form-label text-right">ESL Levies</label>
                            <div class="col-2">
                                <ValidationProvider rules="required" name="eslLevies" v-slot="{ errors }">
                                    <currency-input class="form-control" v-model="appData.acceptedQuote.K_EslLevies"
                                        disabled />
                                    <span class="text-danger">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                            <label class="col-2 col-form-label text-right">GST</label>
                            <div class="col-2">
                                <ValidationProvider rules="required" name="gst" v-slot="{ errors }">
                                    <currency-input class="form-control" v-model="appData.acceptedQuote.K_Gst"
                                        disabled />
                                    <span class="text-danger">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-2 col-form-label text-right">Stamp Duty</label>
                            <div class="col-2">
                                <ValidationProvider rules="required" name="stampDuty" v-slot="{ errors }">
                                    <currency-input class="form-control" v-model="appData.acceptedQuote.K_StampDuty"
                                        disabled />
                                    <span class="text-danger">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                            <label class="col-2 col-form-label text-right">Admin Fee (ex. GST)</label>
                            <div class="col-2">
                                <ValidationProvider rules="required" name="adminFee" v-slot="{ errors }">
                                    <currency-input class="form-control" v-model="appData.acceptedQuote.K_AdminFee"
                                        disabled />
                                    <span class="text-danger">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                            <label class="col-2 col-form-label text-right">Admin Fee GST</label>
                            <div class="col-2">
                                <ValidationProvider rules="required" name="adminFeeGst" v-slot="{ errors }">
                                    <currency-input class="form-control" v-model="appData.acceptedQuote.K_AdminFeeGst"
                                        disabled />
                                    <span class="text-danger">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-2 col-form-label text-right font-weight-bold">Total Insurer
                                Payable</label>
                            <div class="col-2">
                                <ValidationProvider rules="required" name="totalInsurerPayable" v-slot="{ errors }">
                                    <currency-input class="form-control"
                                        v-model="appData.acceptedQuote.K_TotalInsurerPayable" disabled />
                                    <span class="text-danger">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                        </div>

                        <!-- <div class="form-group row mt-5">
              <label class="col-2 col-form-label text-right"
                >Commission Type</label
              >
              <div class="col-2">
                <b-form-radio-group
                  class="mt-3"
                  v-model="appData.acceptedQuote.K_CommissionType"
                  disabled
                >
                  <b-form-radio value="Gross">Gross</b-form-radio>
                  <b-form-radio value="Net">Net</b-form-radio>
                </b-form-radio-group>
              </div>
            </div> -->

                        <!-- <div
              class="form-group row"
              v-if="appData.acceptedQuote.K_CommissionType === 'Gross'"
            >
              <label class="col-2 col-form-label text-right"
                >Insurer Commission (ex. GST)</label
              >
              <div class="col-2">
                <currency-input
                  class="form-control"
                  v-model="appData.acceptedQuote.K_InsurerCommission"
                  disabled
                />
              </div>
              <label class="col-2 col-form-label text-right"
                >Insurer Commission GST</label
              >
              <div class="col-2">
                <currency-input
                  class="form-control"
                  v-model="appData.acceptedQuote.K_InsurerCommissionGst"
                  disabled
                />
              </div>
              <label class="col-2 col-form-label text-right"
                >Insurer Commission %</label
              >
              <div class="col-2">
                <b-input-group append="%">
                  <input
                    type="number"
                    class="form-control"
                    v-model="appData.acceptedQuote.K_InsurerCommissionPercent"
                    disabled
                  />
                </b-input-group>
              </div>
            </div> -->

                        <fees-and-commission :quote="appData.acceptedQuote" :is-read-only="true"></fees-and-commission>

                        <div class="form-group row">
                            <label class="col-2 col-form-label text-right font-weight-bold">Total Premium
                                Payable</label>
                            <div class="col-2">
                                <currency-input class="form-control"
                                    v-model="appData.acceptedQuote.K_TotalPremiumPayable" disabled />
                            </div>
                        </div>

                        <!-- Add CIB Income section -->
                        <!-- <pre>
                            {{ JSON.stringify(appData.acceptedQuote, null, 2) }}
                        </pre> -->
                        <div class="form-group row">
                            <label class="col-2 col-form-label text-right font-weight-bold">Total CIB Income</label>
                            <div class="col-2">
                                <currency-input class="form-control" :value="totalCibIncome" disabled />
                            </div>
                            <label class="col-2 col-form-label text-right">CIB Income GST</label>
                            <div class="col-2">
                                <currency-input class="form-control" :value="totalCibIncomeGst" disabled />
                            </div>
                        </div>
                    </div>
                </b-card>

                <b-card border-variant="secondary" body-class="px-4 py-4" class="mt-10">
                    <div class="form-group row">
                        <label class="col-2 col-form-label text-right font-weight-bold">Enter Invoice Number</label>
                        <div class="col-2">
                            <ValidationProvider rules="required" name="invoiceNumber" v-slot="{ errors }">
                                <input type="text" class="form-control" v-model="appData.transaction.K_InvoiceNumber"
                                    :disabled="isReadOnly || appData.statusFlags.insightDataUpdated
                                        " />
                                <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="col-8">
                            <ValidationProvider :rules="{ 'required-action': { allowFalse: false } }"
                                name="appData.statusFlags.insightDataUpdated" v-slot="{ errors }">
                                <div class="row">
                                    <span class="switch switch-sm switch-outline switch-icon switch-primary">
                                        <label v-show="!localStatusFlags.insightDataUpdated.saving">
                                            <input type="checkbox" v-model="localStatusFlags.insightDataUpdated.value"
                                                @change="flagUpdated('insightDataUpdated')" :disabled="isReadOnly" />
                                            <span></span>
                                        </label>
                                        <b-spinner variant="primary"
                                            v-if="localStatusFlags.insightDataUpdated.saving"></b-spinner>
                                    </span>
                                    <label class="col-form-label font-size-h5 ml-2">
                                        I confirm that the above information has been entered into
                                        INSIGHT.
                                    </label>
                                </div>
                                <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                            <div class="text-danger" v-if="invoiceNumberMissing">
                                Please enter the invoice number.
                            </div>
                        </div>
                    </div>
                </b-card>

                <b-card v-if="appData.statusFlags.insightDataUpdated" border-variant="secondary" body-class="px-4 py-4"
                    class="mt-10">
                    <div class="form-group row">
                        <div class="col-2"></div>
                        <div class="col-2">
                            <button type="button" class="btn btn-primary"
                                @click.prevent="getClientRenewalInvoiceEmailTemplate" style="min-width: 150px;">
                                <i class="fa fa-envelope pb-1"></i>
                                Send Invoice
                            </button>
                        </div>
                        <div class="col-8">
                            <ValidationProvider :rules="{ 'required-action': { allowFalse: false } }"
                                name="appData.statusFlags.invoiceSent" v-slot="{ errors }">
                                <div class="row">
                                    <span class="switch switch-sm switch-outline switch-icon switch-primary">
                                        <label v-show="!localStatusFlags.invoiceSent.saving">
                                            <input type="checkbox" v-model="localStatusFlags.invoiceSent.value"
                                                @change="flagUpdated('invoiceSent')" :disabled="isReadOnly" />
                                            <span></span>
                                        </label>
                                        <b-spinner variant="primary"
                                            v-if="localStatusFlags.invoiceSent.saving"></b-spinner>
                                    </span>
                                    <label class="col-form-label font-size-h5 ml-2">
                                        I confirm that I have sent the invoice to the client.
                                    </label>
                                </div>
                                <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>
                </b-card>

                <b-card v-if="appData.statusFlags.invoiceSent" border-variant="secondary" body-class="px-4 py-4"
                    class="mt-10">
                    <div class="form-group row">
                        <div class="col-2"></div>
                        <div class="col-2">
                            <button type="button" class="btn btn-primary" @click.prevent="handleClosingToInsurerEmail"
                                style="min-width: 150px;">
                                <i class="fa fa-envelope pb-1"></i>
                                Send Closing
                            </button>
                        </div>
                        <div class="col-8">
                            <ValidationProvider :rules="{ 'required-action': { allowFalse: false } }"
                                name="appData.statusFlags.closingSent" v-slot="{ errors }">
                                <div class="row">
                                    <span class="switch switch-sm switch-outline switch-icon switch-primary">
                                        <label v-show="!localStatusFlags.closingSent.saving">
                                            <input type="checkbox" v-model="localStatusFlags.closingSent.value"
                                                @change="flagUpdated('closingSent')" :disabled="isReadOnly" />
                                            <span></span>
                                        </label>
                                        <b-spinner variant="primary"
                                            v-if="localStatusFlags.closingSent.saving"></b-spinner>
                                    </span>
                                    <label class="col-form-label font-size-h5 ml-2">
                                        I confirm that I have sent the Closing to the insurer.
                                    </label>
                                </div>
                                <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>
                </b-card>

                <b-card v-if="appData.statusFlags.closingSent" border-variant="secondary" body-class="px-4 py-4"
                    class="mt-10">
                    <div class="form-group row">
                        <div class="col-2"></div>
                        <div class="col-2">
                            <button type="button" class="btn btn-primary"
                                @click.prevent="getCertificateOfCurrencyEmailTemplate" style="min-width: 150px;">
                                <i class="fa fa-envelope pb-1"></i>
                                Send CoC
                            </button>
                        </div>
                        <div class="col-8">
                            <ValidationProvider :rules="{ 'required-action': { allowFalse: false } }"
                                name="appData.statusFlags.cocSent" v-slot="{ errors }">
                                <div class="row">
                                    <span class="switch switch-sm switch-outline switch-icon switch-primary">
                                        <label v-show="!localStatusFlags.cocSent.saving">
                                            <input type="checkbox" v-model="localStatusFlags.cocSent.value"
                                                @change="flagUpdated('cocSent')" :disabled="isReadOnly" />
                                            <span></span>
                                        </label>
                                        <b-spinner variant="primary" v-if="localStatusFlags.cocSent.saving"></b-spinner>
                                    </span>
                                    <label class="col-form-label font-size-h5 ml-2">
                                        I confirm that I have sent the Certificate of Currency to
                                        the client.
                                    </label>
                                </div>
                                <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>
                </b-card>

                <div v-if="appData.statusFlags.cocSent && !isReadOnly" class="row justify-content-center mt-5 mb-5">
                    <h4>
                        Please click the 'Finalise Transaction' button below, to finalise the
                        renewal.
                    </h4>
                </div>
            </b-card>
        </div>
    </ValidationObserver>
</template>

<style>
.form-control.min-rows {
    min-height: calc(1.5em * 3 + 2px);
}

.card {
    border: 1px solid #dee2e6;
    border-radius: 0.5rem;
}

.card-title {
    font-size: 1.25rem;
    font-weight: 500;
}
</style>

<script>
import PolicySummary from "../../../common/PolicySummary";
import PolicyAlert from "../../../common/PolicyAlert";
import Instructions from "../../../common/Instructions";
import DeclarationForm from "../../../common/DeclarationForm.vue";
import FeesAndCommission from "../../../common/FeesAndCommission";

export default {
    name: "InvoicePage",


    components: {
        PolicySummary,
        PolicyAlert,
        Instructions,
        DeclarationForm,
        FeesAndCommission,
    },
    props: {
        appData: Object,
        isReadOnly: Boolean,
    },
    data() {
        return {
            invoiceNumberMissing: false,
            localStatusFlags: {
                insightDataUpdated: {
                    value: this.appData.statusFlags.insightDataUpdated,
                    saving: false,
                },
                invoiceSent: {
                    value: this.appData.statusFlags.invoiceSent,
                    saving: false,
                },
                closingSent: {
                    value: this.appData.statusFlags.closingSent,
                    saving: false,
                },
                cocSent: {
                    value: this.appData.statusFlags.cocSent,
                    saving: false,
                },
            },
            currencyConfig: {
                allowNegative: false,
                distractionFree: false,
                precision: 2,
            },
            excessDataTableConfiguration: {
                title: "Excesses",
                currentTitle: "Current Value",
                renewalTitle: "Renewal Value",
                showCurrentValue: false,
            },
            sumsDataTableConfiguration: {
                title: "Sums Insured",
                currentTitle: "Current Value",
                renewalTitle: "Renewal Value",
                showCurrentValue: false,
            },
        };
    },

    computed: {
        instructions: function () {
            return `Shown below are the schedule and invoicing details relating to the
          above mentioned Policy. Please enter the details shown below into
          INSIGHT and then confirm that you have done this.`;
        },

        totalCibIncome() {
            if (!this.appData?.acceptedQuote) return 0;

            const quote = this.appData.acceptedQuote;
            if (quote.K_CommissionType === 'Net') {
                // For Net commission, use just the CIB Income
                return quote.K_CibIncome || 0;
            } else {
                // For Gross commission, add CIB Income and Broker Fee
                return (quote.K_CibIncome || 0) + (quote.K_BrokerFee || 0);
            }
        },

        totalCibIncomeGst() {
            if (!this.appData?.acceptedQuote) return 0;

            const quote = this.appData.acceptedQuote;
            if (quote.K_CommissionType === 'Net') {
                // For Net commission, use just the CIB Income GST
                return quote.K_CibIncomeGst || 0;
            } else {
                // For Gross commission, add CIB Income GST and Broker Fee GST
                return (quote.K_CibIncomeGst || 0) + (quote.K_BrokerFeeGst || 0);
            }
        }
    },

    methods: {
        flagUpdated: function (flagName) {
            this.invoiceNumberMissing = false;

            if (flagName === "insightDataUpdated") {
                let invoiceNumber = this.appData.transaction.K_InvoiceNumber;
                if (!invoiceNumber) {
                    this.invoiceNumberMissing = true;
                    this.$nextTick(() => {
                        this.localStatusFlags.insightDataUpdated.value = false;
                    });
                    return;
                } else {
                    // Save invoice number to server
                    this.$renewalDataService
                        .saveInvoiceNumber(this.appData.transaction.K_InvoiceNumber)
                        .catch((error) => {
                            console.log(error);
                            // TODO HANDLE ERROR
                        });
                }
            }

            let flagData = this.localStatusFlags[flagName];
            if (flagData) {
                flagData.saving = true;

                this.$renewalDataService
                    .updateTransactionStatus(flagName, flagData.value)
                    .then(({ data }) => {
                        let updatedStatus = data.result;
                        if (updatedStatus) {
                            // Update appData flags
                            this.appData.statusFlags[flagName] = flagData.value;

                            // Update transaction status
                            let txn = this.appData.transaction;
                            txn.K_Status = updatedStatus.K_Status;
                            txn.K_StatusDetails = updatedStatus.K_StatusDetails;
                            txn.K_NextStep = updatedStatus.K_NextStep;
                            txn.K_DueInWeek = updatedStatus.K_DueInWeek;
                            txn.K_WeekStartDate = updatedStatus.K_WeekStartDate;

                            // Fire event to update the global transaction status on the UI
                            this.$emit("statusUpdated");

                            // If a flag was set to false, manually set all future flags to false.
                            if (flagData.value === false) {
                                if (flagName === "insightDataUpdated") {
                                    this.localStatusFlags.invoiceSent.value = false;
                                    this.appData.statusFlags.invoiceSent = false;

                                    this.localStatusFlags.closingSent.value = false;
                                    this.appData.statusFlags.closingSent = false;

                                    this.appData.statusFlags.cocSent = false;
                                } else if (flagName === "invoiceSent") {
                                    this.localStatusFlags.closingSent.value = false;
                                    this.appData.statusFlags.closingSent = false;

                                    this.appData.statusFlags.cocSent = false;
                                }
                            }
                        }

                        flagData.saving = false;
                    })
                    .catch((error) => {
                        // Reset to original value
                        flagData.value = !flagData.value;
                        flagData.saving = false;
                        console.log(error);
                        // TODO HANDLE ERROR
                    });
            }
        },

        handleClosingToInsurerEmail() {
            const acceptedInsurerId = this.appData.acceptedQuote?.K_Insurer?.id;
            const acceptedInsurerContact = this.appData.selectedInsurers?.find((insurer) => insurer.id === acceptedInsurerId)?.selectedContact;
            this.$emit("handleClosingToInsurerEmail", acceptedInsurerContact);
        },

        getClientRenewalInvoiceEmailTemplate: function () {
            this.$commonDataService
                .getEmailTemplate("RENEWAL_CLIENT_INVOICE")
                .then(({ data }) => {
                    let emailTemplate = data.result;
                    const url = window.URL.createObjectURL(new Blob([emailTemplate]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute(
                        "download",
                        `cib-email-${new Date().getTime()}.eml`
                    );
                    document.body.appendChild(link);
                    link.click();
                })
                .catch((error) => {
                    this.$emit("handleError", error);
                });
        },

        getCertificateOfCurrencyEmailTemplate: function () {
            this.$commonDataService
                .getEmailTemplate("SEND_CERTIFICATE_OF_CURRENCY")
                .then(({ data }) => {
                    let emailTemplate = data.result;
                    const url = window.URL.createObjectURL(new Blob([emailTemplate]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute(
                        "download",
                        `cib-email-${new Date().getTime()}.eml`
                    );
                    document.body.appendChild(link);
                    link.click();
                })
                .catch((error) => {
                    this.$emit("handleError", error);
                });
        },

        getInsurerRenewalClosingEmailTemplate: function () {
            this.$commonDataService
                .getEmailTemplate("ENDORSEMENT_INSURER_CLOSING")
                .then(({ data }) => {
                    let emailTemplate = data.result;
                    const url = window.URL.createObjectURL(new Blob([emailTemplate]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute(
                        "download",
                        `cib-email-${new Date().getTime()}.eml`
                    );
                    document.body.appendChild(link);
                    link.click();
                })
                .catch((error) => {
                    this.$emit("handleError", error);
                });
        },

        getClientEndorsementCertificateEmailTemplate: function () {
            this.$commonDataService
                .getEmailTemplate("ENDORSEMENT_INSURER_CLOSING")
                .then(({ data }) => {
                    let emailTemplate = data.result;
                    const url = window.URL.createObjectURL(new Blob([emailTemplate]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute(
                        "download",
                        `cib-email-${new Date().getTime()}.eml`
                    );
                    document.body.appendChild(link);
                    link.click();
                })
                .catch((error) => {
                    this.$emit("handleError", error);
                });
        },
    },
};
</script>
